<template>
  <div
    v-if="memberCertStatus != 2"
    class="page-content-box"
    :style="{
      backgroundImage: 'url(' + pagebg1 + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain'
    }"
  >
    <div
      class="info-banner"
      :style="{
        backgroundImage: 'url(' + bannerImg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }"
    >
      <head-main ref="head" class="no-fixed"></head-main>
    </div>
    <div class="inheritor-box">
      <div class="inheritor-regular">
        <div class="in-text-l">
          <p>
            传承人是直接参与非物质文化遗产传承、使非物质文化遗产能够沿袭的个人或群体(团体)。非物质文化遗产最重要的活态载体
          </p>
          <p>
            如果您是南平地区非遗传承人，请完成下面步骤完成传承人认证。让群众关注您的动态传承非物质文化
          </p>
        </div>
        <div class="in-view">
          <span
            class="rz-view"
            :style="{
              backgroundImage: 'url(' + zsmini + ')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }"
          ></span>
          <p>认证通过证书预览</p>
        </div>
      </div>
      <div class="custon-line">
        <span>
          <i class="tit-i1"></i>
          <i class="tit-red">认证</i>步骤
          <i class="tit-i2"></i>
        </span>
      </div>
      <div class="custon-step">
        <el-steps :active="active" align-center finish-status="success" process-status="wait">
          <el-step title="第一步" description="根据提示填写个人信息"></el-step>
          <el-step title="第二步" description="上次相关资料信息"></el-step>
          <el-step title="第三步" description="等待审核结果"></el-step>
        </el-steps>
        <div class="step-btn">
          <el-button class="step-btns" :class="{ inacitve: active !== 0 }" :disabled="active !== 0" @click="openInfo"
            >填写信息
          </el-button>
          <el-button class="step-btns" :class="{ inacitve: active !== 1 }" :disabled="active !== 1" @click="openUpload"
            >上传资料
          </el-button>
          <el-button class="step-btns" :class="{ inacitve: true }" disabled>等待结果</el-button>
        </div>
      </div>
      <p v-if="!this.$store.state.username" class="action-p">
        传承人认证，请在<span style="cursor: pointer" @click="toLogin">登录</span>状态下进行
      </p>
    </div>
    <dialog-info
      ref="info"
      :config="config"
      :beforeClose="beforeClose"
      :active.sync="active"
      @close="resetForm"
      :inheritor-form.sync="inheritorForm"
    ></dialog-info>
    <dialog-upload
      ref="upload"
      :config="config"
      :beforeClose="beforeClose"
      :active.sync="active"
      @close="resetForm"
      :inheritor-pics.sync="inheritorPics"
      @submit="submit"
    ></dialog-upload>

    <botto-com class="bottom-white no-fixed"></botto-com>
  </div>
  <certificate-passed v-else></certificate-passed>
</template>
<script>
let DialogInfo = () => import('./popinfo')
let DialogUpload = () => import('./popupload')
let CertificatePassed = () => import('./CertificatePassed')
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'

export default {
  components: {
    HeadMain,
    BottoCom,
    DialogInfo,
    DialogUpload,
    CertificatePassed
  },
  data() {
    let memberCertStatus = localStorage.getItem('memberCertStatus')
    let active = 0
    if (memberCertStatus == 0) {
      active = 0
    } else if (memberCertStatus == 1) {
      active = 2
    } else if (memberCertStatus == 2) {
    }
    return {
      memberCertStatus,
      active,
      bannerImg: require('../../assets/images/bg6.jpg'),
      pagebg1: require('../../assets/images/page-bg1.jpg'),
      zsmini: require('../../assets/images/zs-mini.png'),
      config: {
        width: '800px',
        // title: '温馨提示',
        center: true
        // btnTxt: ['取消', '打开内层'],
      },
      inheritorForm: {},
      inheritorPics: {}
    }
  },
  methods: {
    toLogin() {
      this.$refs.head.open('login')
    },
    submit() {
      let params = { ...this.inheritorForm, ...this.inheritorPics }
      let headers = {
        'Captcha-Key': params.captchaKey,
        'Captcha-Code': params.captchaCode
      }
      delete params.captchaKey
      delete params.captchaCode
      this.axios
        .post('/api/portal/inheritor/auth/tobeInheritor', params, {
          headers
        })
        .then(res => {
          this.$message.success('提交成功')
          this.$refs.upload.cancel()
          this.active = 2
        })
    },
    openInfo() {
      if (!this.$store.state.username) {
        this.$message.warning('传承人认证，请在登录状态下进行')
        return
      }
      this.$refs.info.open(cancel => {
        // cancel();
        // console.log('点击提交按钮了')
      })
    },
    openUpload() {
      this.$refs.upload.open(cancel => {
        // cancel();
        // console.log('点击提交按钮了')
      })
    },

    beforeClose() {
      console.log('关闭前')
    },
    resetForm() {
      // 这里可以写重置表单的实现
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped></style>
